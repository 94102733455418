





































.first-addon-btn.v-btn {
  margin-right: 0;
  margin-left: 0;
  font-size: 11px;
  &.v-btn--small {
    height: 24px;
    padding: 0 6px;
  }
  .v-icon--left {
    margin-right: 5px;
  }
}
